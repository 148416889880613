<template>
  <main class="container">
    <br />

    <div class="row">
      <div class="card rounded-lg border border-dark">
        <div class="row">
          <div class="row my-5 mx-auto">
               <img src="@/assets/Meteo_Uzal_TR/Meteo_Uzal_TR-1.png" alt="">
              <img src="@/assets/Meteo_Uzal_TR/Meteo_Uzal_TR-2.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style></style>
